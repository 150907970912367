import { render, staticRenderFns } from "./UsedShopList.vue?vue&type=template&id=38031662&scoped=true"
var script = {}
import style0 from "@/assets/css/set.css?vue&type=style&index=0&id=38031662&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/margin.css?vue&type=style&index=1&id=38031662&prod&scoped=true&lang=css&external"
import style2 from "@/assets/css/layout.css?vue&type=style&index=2&id=38031662&prod&scoped=true&lang=css&external"
import style3 from "@/assets/css/parts.css?vue&type=style&index=3&id=38031662&prod&scoped=true&lang=css&external"
import style4 from "@/assets/css/access.css?vue&type=style&index=4&id=38031662&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38031662",
  null
  
)

export default component.exports